import React from 'react';
import './App.css';
import LogOutButton from "./Components/LogOutButton";
import UserInfoView from "./Components/UserInfoView";
import {useAuth0} from "@auth0/auth0-react";
import DisplayOnAuthenticationState from "./Components/DisplayOnAuthenticationState";
import Loading from "./Components/Loading";

function App() {
    const {isLoading, error, logout, isAuthenticated, loginWithRedirect} = useAuth0();

    if (isLoading) {
        return (<Loading/>)
    }
    
    if (error){
        return (
            <dialog open>
                <p>{error.message}</p>
                <form method="dialog">
                    <button autoFocus onClick={async () => await logout()}>:(</button>
                </form>
            </dialog>
        )
    }
    
    if (!isAuthenticated){
        return (
            <dialog open>
                <h1>Welcome to BetterBookmarks.</h1>
                <p>
                    The service is in early access mode, with no access to the public.
                    <br />
                    Login will be rejected if your user is not marked for early access.
                </p>
                <form method="dialog">
                    <button autoFocus onClick={async () => await loginWithRedirect()}>Sign up / Login</button>
                </form>
            </dialog>
        )
    }

    return (
        <>
            <LogOutButton/>
            <DisplayOnAuthenticationState authenticationState={"authenticated"}>
                <UserInfoView/>
            </DisplayOnAuthenticationState>
            <DisplayOnAuthenticationState authenticationState={"authenticated"}>
                <a href="https://api.betterbookmarks.eu" target="_blank" rel="noreferrer">Go to api</a>
            </DisplayOnAuthenticationState>
        </>
    );
}

export default App;
