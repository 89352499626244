import styled from "styled-components";

const Loading = styled.div`
  border: 0.25em dotted #f3f3f3; /* Light grey */
  border-top: 0.25em dotted #320032; /* Blue */
  border-radius: 50%;
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  position: absolute;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`


export default Loading