import {useAuth0} from "@auth0/auth0-react";
import {JSX} from "react";

interface DisplayOnAuthenticationStateProps {
    authenticationState : "authenticated" | "unauthenticated"
    children : JSX.Element
}

const DisplayOnAuthenticationState = (props : DisplayOnAuthenticationStateProps) => {
    const {isAuthenticated} = useAuth0();
    
    const whenAuthenticated = props.authenticationState === "authenticated";

    return isAuthenticated === whenAuthenticated ? props.children : <></>
}

export default DisplayOnAuthenticationState